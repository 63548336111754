
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import parser from 'accept-language-parser';
import { GetServerSideProps } from 'next';
import getConfig from 'next/config';

import config from '../../config/i18n';
import Home from '../components/pages/home';
import { setShippingLocation } from '../services/shipping-locations/set';
import { ADVANCED_VIEW_ACTIVE } from '../static/cookies';
import { isGlobalUser } from '../utils/is-global-user';
import { isUKuser } from '../utils/is-uk-user';
import { isUSuser } from '../utils/is-us-user';
import getLoggedUser from '../utils/react-query/query-options/get-logged-user';
import { redirectForAdvanced } from '../utils/redirect-for-advanced';
import { setLanguage } from '../services/language/set';
import { loadLanguages } from '../components/modules/language-selector';

const { publicRuntimeConfig } = getConfig();

 const _getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  locale = 'en',
  query,
}) => {
  isUSuser(req, res);
  isUKuser(req, res);
  isGlobalUser(req, res);

  if (query.shipping_location) {
    await setShippingLocation({
      locale,
      shipping_location: query.shipping_location as string,
      csrftoken: req.cookies['csrftoken'] ?? '',
    });
    res.setHeader(
      'set-cookie',
      `_shipping_location=${query.shipping_location}; Path=/`
    );

    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  const pathname = req.url;
  const _language = req.cookies['_language'];

  if (query.hasOwnProperty('rld_local')) {
    res.setHeader('cache-control', `no-store`);
    res.setHeader('set-cookie', `_language=${locale}; Path=/`);
    return {
      redirect: {
        destination: `/${locale}`,
        permanent: false,
      },
    };
  }

  // pathname should always be '/' here because of the pathname
  if (pathname === '/' && locale === 'en') {
    if (_language && _language !== 'en' && config.locales.includes(_language)) {
      return {
        redirect: {
          destination: `/${_language}`,
          permanent: false,
        },
      };
    } else {
      const acceptLanguage = req.headers['accept-language'];
      const locale = parser.pick(config.locales, acceptLanguage || '');
      if (!_language && locale && locale !== 'en') {
        return {
          redirect: {
            destination: `/${locale}`,
            permanent: false,
          },
        };
      }
    }
  }

  const { default: getGenericClient } = await import('../server/client');
  const genericClient = getGenericClient();
  const { fetchGroups } = await import('../services/groups');
  const { fetchBrands } = await import('../services/vehicles');
  const { fetchFaqs } = await import('../services/faqs');
  const { fetchLanguages } = await import('../services/language/list');
  const isBparts = process.env.NEXT_PUBLIC_TENANT === '1';

  const [user] = await Promise.all([
    genericClient.fetchQuery(...getLoggedUser({ locale }, req)),
    // TODO: FETCH GROUPS WITH BRANDS STOCK (isBrandEnabled)
    genericClient.prefetchQuery(
      ['groups', locale],
      ({ queryKey: [, locale] }) => fetchGroups({ locale }, req)
    ),
    !publicRuntimeConfig.isFeatureBrandEnabled &&
      genericClient.prefetchQuery(
        ['brands', locale],
        ({ queryKey: [, locale] }) => fetchBrands({ locale }, req)
      ),
    genericClient.prefetchQuery(
      ['faqs', locale, 'home'],
      ({ queryKey: [, locale, slug] }) => fetchFaqs({ locale, slug }, req)
    ),
    genericClient.prefetchQuery(
      ['languages-list', locale, config.locales, isBparts],
      loadLanguages
    ),
  ]);

  const { default: retrieveStateAndClear } = await import(
    '../utils/retrieve-state-and-clear'
  );

  if (
    user?.staff?.is_pro &&
    !!parseInt(req.cookies[ADVANCED_VIEW_ACTIVE] || '0')
  ) {
    return redirectForAdvanced({
      locale,
    });
  }

  return {
    props: {
      dehydratedState: retrieveStateAndClear(genericClient),
    },
  };
};

export default Home;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  